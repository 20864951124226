#top-header {
    background-color: #191919;
    color: #aaa;

    #top-header-logo {
        margin: 15px 0;
        height: 50px;
        width: 290px;
        background: url('/images/site-odc/logo.png') no-repeat;
        text-indent: -9999px;
    }

    #top-header-name-container {
        line-height: 1;

        #top-header-name {
            margin-top: 33px;
        }
    }
}

.top-banner {
    border-top: 1px solid #6cbd92;
    background: #86c340;
    background-image: linear-gradient(bottom, rgb(93,180,133) 0%, rgb(134,195,64) 100%);
    margin-bottom: 1em;

    h1 {
        margin: 20px 0;
        color: #fff;
        font-size: 30px;
    }

    h2 {
        margin: 20px 0;
        color: #fff;
        font-size: 24px;
    }
}

/* Footer */
#bottom-footer {
    background: #191919;
    color: #828282;
    padding: 30px 0;
    font-size: 13px;
    margin-top: 1em;
}

.navbar-custom {
    background-color: #4b9376;
}
